import React from "react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import FaqLayout from "../../components/faq-layout"

export default () => (
  <FaqLayout subMenuActivo="siniestros">
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <h6>
            <a className="collapsed" href="#a" data-toggle="collapse">
              Choqué ¿Qué hago, ¿qué necesito y a donde lo denuncio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <p>
              En caso de que hubiera terceros lesionados (personas diferentes al conductor y su
              grupo familiar), comunícate con el teléfono indicado en tu tarjeta de circulación o en
              “Contacto”.
            </p>
            <p>
              Necesitaras obtener siempre los datos del otro conductor (apellido y nombre,
              DNI/cédula de identidad y teléfono), del vehículo (marca y modelo y patente y compañía
              aseguradora) y de testigos (apellido y nombre, DNI/cédula identidad y dirección) en
              caso de accidente con otro/s automóvil/es.
            </p>
            <p>
              Aconsejamos sacar fotos si la situación lo permite, ya que te ayudará al momento de
              denunciar al siniestro.
            </p>
            <p>
              Recomendamos no realizar ningún tipo de acuerdo con terceros, ni firmar documentación
              alguna que comprometa tus intereses como Asegurado y/o los de tu aseguradora.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Me chocaron, ¿Qué necesito hacer?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <p>
              En caso de que hubiera terceros lesionados (personas diferentes al conductor y su
              grupo familiar), comunícate con el teléfono indicado en tu tarjeta de circulación o en
              “Contacto”.
            </p>
            <p>
              Tendrás que obtener siempre los datos del otro conductor (apellido y nombre,
              DNI/cédula de identidad y teléfono), del vehículo (marca y modelo y patente y compañía
              aseguradora) y de testigos (apellido y nombre, DNI/cédula identidad y dirección) en
              caso de accidente con otro/s automóvil/es.
            </p>
            <p>
              Aconsejamos sacar fotos si la situación lo permite, ya que te ayudará al momento de
              denunciar al siniestro.
            </p>
            <p>
              Recomendamos no realizar ningún tipo de acuerdo con terceros, ni firmar documentación
              alguna que comprometa tus intereses como Asegurado y/o los de tu aseguradora.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Tuve un robo, un choque o un incendio de gran magnitud, ¿Qué necesito hacer y a dónde
              lo denuncio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <p>
              En caso de un siniestro podés realizar la denuncia a través de “Contacto”, que te
              permite ingresar tu denuncia de una forma ágil y rápida, las 24 horas del día, los 365
              días del año, o también podés hacerlo en la web de tu aseguradora, en caso de estar
              disponible esta posibilidad.
            </p>
            <p>
              <strong>Pérdida Total (Por Robo)</strong> Deberás realizar la correspondiente denuncia
              policial primero para luego poder adjuntarla. Por lo general los plazos ante robo
              total son de 30 días corridos, si dentro de este lapso de tiempo la unidad no aparece
              la aseguradora procederá a solicitarte la baja del auto (esto significa generar la
              baja ante municipalidad, rentas, etc de la unidad) para luego indemnizarte. Si la
              unidad aparece deberás informarnos vía mi cuenta.
            </p>
            <p>
              <strong>Pérdida Total (Por incendio)</strong> Deberás realizar la denuncia presentando
              constancia de bomberos. La Compañía asignará un peritaje de la unidad para luego
              resolver el caso y poder indemnizar en caso de que ésto corresponda. <br />
              Una vez aceptado el siniestro, algunas aseguradoras asignan un gestor para agilizar el
              pago del siniestro y ayudarte a cumplimentar los requisitos. Te adelantamos la
              documentación necesaria para dar de baja tu vehículo:
            </p>
            <ul className="mb-2">
              <li>Formulario 02: Certificado de Dominio</li>
              <li>Copia fiel del título del automotor</li>
              <li>Libre deuda de infracciones</li>
              <li>Libre deuda de patente</li>
              <li>Baja de patente (Rentas)</li>
              <li>Recibos de patente (originales, los que hubiere)</li>
              <li>Llaves del vehículo</li>
              <li>
                Formulario de Lucha y Prevención de Lavado de dinero, para vehículos cuyo valor sea
                superior a $ 200.000 (Resolución de la UIF Personas Físicas y/o Jurídicas)
              </li>
              <li>
                Baja del vehículo con Formulario 4D (en caso de destrucción o incendio total)
                Formulario F15 y CETA - Clave fiscal AFIP (en caso de robo total
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Choqué o me chocaron, me encontré con el auto dañado. ¿Qué hago, que necesito y a
              donde lo denuncio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <p>
              En caso de que hubiera terceros lesionados (personas diferentes al conductor y su
              grupo familiar), comunícate con el teléfono indicado en tu tarjeta de circulación o en
              “Contacto”.
            </p>
            <p>
              Necesitaras obtener siempre los datos del otro conductor (apellido y nombre,
              DNI/cédula de identidad y teléfono), del vehículo (marca y modelo y patente y compañía
              aseguradora) y de testigos (apellido y nombre, DNI/cédula identidad y dirección) en
              caso de accidente con otro/s automóvil/es.
            </p>
            <p>
              Aconsejamos sacar fotos si la situación lo permite, ya que te ayudará al momento de
              denunciar al siniestro.
            </p>
            <p>
              Recomendamos no realizar ningún tipo de acuerdo con terceros, ni firmar documentación
              alguna que comprometa tus intereses como Asegurado y/o los de tu aseguradora.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Me robaron la rueda, tuve daño a cristales y cerraduras, ¿Cómo lo denuncio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <p>
              Comunícate vía “Contacto” para informarnos tu denuncia para que te ayudemos a
              gestionar la reposición de acuerdo al servicio de cada aseguradora.
            </p>
            <p>
              Algunas aseguradoras cuentan con un servicio especial para solucionar este tipo de
              escenarios de forma inmediata y sin desembolso de dinero. Vas a necesitar denuncia
              policial cuando se trate de intento de robo o robo.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              Me agarró una tormenta con granizo ¿Cómo lo denuncio?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body>
            <p>
              Comunícate vía “Contacto” para informarnos tu denuncia para que te ayudemos a
              gestionar la reposición de acuerdo al servicio de cada aseguradora.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="6">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Cuánto tiempo tengo para denunciar un siniestro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="6">
          <Card.Body>
            <p>
              El plazo es dentro de las 72hs. de ocurrido el siniestro. La rapidez en que se
              efectuara la denuncia tiene relación directa con el costo final del siniestro, en
              especial cuando se trata donde hay terceros lesionados.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="7">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿En dónde denunciar un siniestro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="7">
          <Card.Body>
            <p>
              En caso de un siniestro podés realizar la denuncia a través de “Contacto”, que te
              permite ingresar tu denuncia de una forma ágil y rápida, las 24 horas del día, los 365
              días del año, o también podés hacerlo en la web de tu aseguradora, en caso de estar
              disponible esta alternativa.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="8">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿Como sigo mi siniestro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="8">
          <Card.Body>
            <p>
              Si el mismo lo gestionaste a través de Web Del Seguro, te serán informados los avances
              por email. Si lo hiciste directamente con la aseguradora, tendrás que continuarlo por
              ese canal.
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="9">
          <h6>
            <a className="collapsed" href="#b" data-toggle="collapse">
              ¿En qué casos la Compañía puede rechazar un siniestro?
            </a>
          </h6>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="9">
          <Card.Body>
            <p>Entre los más fundamentales:</p>
            <ul className="mb-2">
              <li>Si la póliza está impaga</li>
              <li>Si la cobertura no está incluida, por ejemplo, daños parciales.</li>
              <li>
                Si el conductor no está habilitado para conducir esa categoría de vehículo o tenga
                el registro vencido.
              </li>
              <li>Si el automóvil tiene un uso distinto al que figura en la póliza de seguro.</li>
              <li>
                Si los daños son ocasionados por dolo o culpa grave del asegurado o del conductor
              </li>
              <li>
                Cuando el vehículo no se encuentra habilitado para circular conforme a las
                disposiciones legales vigentes.
              </li>
              <li>
                Cuando el conductor maneja bajo la influencia de cualquier droga desinhibidora,
                alucinógena o somnífera, o en estado de ebriedad.
              </li>
              <li>
                Cuando no se respeten las normas de tránsito. (Barreras ferroviarias, exceso de
                velocidad, transitar en contramano, etc).
              </li>
              <li>Por falta de denuncia (caducidad).</li>
              <li>Por reticencia o falsedad en cualquier información dada.</li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </FaqLayout>
)
